.filterContainer {
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 0 1rem;
    margin: 2rem 0;
}

.sliderContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 30px; 
}

.configButton {
    display: flex;
    flex-direction: column;
    justify-self: end;
    width: 30%;
}
