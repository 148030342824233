@import 'App.scss';

.connectedAccountsContainer {
    .tabContainer {
      width: 40rem;
      margin-right: auto;
      margin-left: initial;
    }
    .bladeBodyContainer {
      .bladeTabContainer {
        display: block;
      }
    }
  }

.invitationButton {
  background-color: orange !important;
  color: black !important;
}