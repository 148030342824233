@import "App.scss";

.textFieldContainer {
  grid-template-columns: auto auto auto;
  grid-column-gap: 2rem;
}

.loader {
  min-height: 100px;
  justify-content: center;
  align-items: center;
}

.actionContainer {
  grid-template-columns: auto auto;
  justify-content: end;
  grid-gap: 1rem;
}

.flexItem {
  display: inline-flex;
}

.secondFlexItem{  
  display:inline-flex;
  flex: auto;
  flex-direction:row-reverse;
}