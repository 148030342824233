@import "App.scss";

.emailTemplateContainer {
  .tabContainer {
    width: 40rem;
    margin-right: auto;
    margin-left: initial;
  }
}

.bladeBodyContainer {
  max-height: 20rem;
}
