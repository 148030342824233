@import 'App.scss';

.bladeBodyContainer {
  height: 5rem;
  .bladeTabContainer {
    display: block;
    .badgeContainer {
      align-items: center;
      margin: 1rem 0;
      grid-gap: 1rem;
      display: flex;
    }
  }
  .documentsSection {
    margin-top: 2rem;
    .docItemContainer {
      grid-template-columns: auto 1fr auto;
      align-items: center;
      grid-gap: 1rem;
      border-bottom: 1px solid var(--base-paper-f-8-f-8-f-8, #f8f8f8);
      padding: 12px 0rem;
    }
  }
}