@import 'App.scss';

.headerContainer {
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  padding-bottom: 16px;

  .headerAction {
    grid-template-columns: auto auto;
    grid-gap: 1rem;
    height: 2rem;
    width: auto;
  }
}
