@import 'App.scss';

.modalContainer {
  padding: 2rem 4rem;
  .headerContainer {
    justify-content: center;
    div {
      text-align: center;
    }
  }
  .searchFieldContainer {
    grid-template-columns: 2rem auto;
    align-items: center;
  }
  .mcDotField {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
  .btnContainer {
    margin-top: 1rem;
  }
  .fullLogo {
    img {
      object-fit: contain;
    }
  }
  .backdrop {
    background-color: '#fff'
  }
}

/* Media Query for mobile mode */
@media (max-width: 768px) {
  .modalContainer {
    padding: 1rem;
    .typoContainer {
      span {
        font-size: 12px;
      }
      div {
        font-size: 12px;
      }
    }
    .fullLogo {
      img {
        width: 200px;
        height: 50px;
        object-fit: contain;
      }
      svg {
        width: 200px;
        height: 50px;
      }
    }
    .mcDotField {
      grid-template-columns: 1fr;
      grid-gap: 5px;
    }
  }
}
