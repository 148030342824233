@import 'App.scss';

.bladeBodyContainer {
  height: 5rem;
  .searchContainer {
    display: flex;
    gap: 2rem;
    .fieldsContainer {
      grid-template-columns: repeat(3, auto);
      grid-gap: 1rem;
    }
    .searchBtn {
      margin: auto;
    }
  }
  .resultsContainer {
    margin-top: 1rem;
    .emptyResultContainer {
      justify-content: center;
      margin-top: 8rem;
      grid-gap: 1rem;
    }
  }
  .searchFieldContainer {
    grid-template-columns: 2rem auto;
    align-items: center;
  }
}

.inviteCarrierContainer {
  padding: 1rem;
  .description {
    text-align: center;
    margin: 1rem;
  }
  .fieldItemContainer {
    grid-template-columns: auto auto auto auto;
    grid-gap: 2rem;
  }
}
