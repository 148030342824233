@import "App.scss";

.emailTemplateContainer {
  .tabContainer {
    width: 40rem;
    margin-right: auto;
    margin-left: initial;
  }
}

.bladeBodyContainer {
    max-height: 20rem;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}
