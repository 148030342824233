.loading-wrapper {
    position: relative;
}

.spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e087;
    z-index: 10;
}

.content {
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.3s ease-out;
    &.disabled {
        pointer-events: none;
        opacity: 0.7; 
        transition: opacity 0.3s ease-out;
    }
}