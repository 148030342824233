@import 'App.scss';

.headerContainer {
    grid-template-columns: 1fr auto;

    .headerTitleContainer {
      display: flex !important;
      align-items: center;
    }
  }
  
  /* ScrollbarHide.css */
  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    width: 0.5em; /* Set the width of the hidden scrollbar */
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; /* Set the background color of the track */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: transparent; /* Set the color of the handle */
  }