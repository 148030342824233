@import 'App.scss';

.menuList {
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.logoContainer {
  display: flex;
  justify-content: center;
  img {
    height: 50px;
    width: auto;
  }
}

.listItem {
  padding: 1rem;
}

.mobileHeader {
  grid-template-columns: auto 1fr;
  background: white;
  padding: 1rem 0;
  box-shadow: -6px -3px 6px 2px;
  position: fixed;
  z-index: 1200;
}

.stickyMenuList {
  position: sticky !important;
  top: 90%;
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.textEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.mobileMenuList {
  width: 15rem;
}

.leftIconButton {
  position: fixed !important;
  z-index: 1201;
  left: 240px;
  padding: 3px !important;
  background: $white !important;
  top: 60px;
  box-shadow: 0px 1px 3px 0px;
}

.rightIconButton {
  left: 51px;
}

.active > svg > g path {
  stroke: $primary;
}

.active > svg > path {
  fill: $primary;
}

.mobileMenuHeader {
  justify-content: start !important;
}
