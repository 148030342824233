@import 'App.scss';

.connectedAccountsContainer {
  display: grid;
  .summaryMainContainer {
    grid-template-rows: 10rem 1fr;
  }
  .summaryHeaderContainer {
    margin-top: 1rem;
    background-color: rgb(0, 0, 0);
    border-radius: 11px;
    margin-bottom: 1rem;
    .summaryList {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 10px 20px;
    }
    .itemContainer {
      grid-template-columns: 2rem auto;
      align-items: center;
      grid-gap: 8px;
      .summaryDataContainer {
        display: grid;
        grid-template-columns: 2rem 1fr; 
        align-items: center;
        grid-gap: 8px;
      }
      .summaryContentContainer {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
