@import 'App.scss';

iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.bladeBodyContainer {
    max-height: 15rem;
    grid-gap: 0.7rem;
}

