@import 'App.scss';

.wrapper {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.containerShadow > div {
  box-shadow: 0px 16px 24px 4px rgba(18, 28, 45, 0.2);
}

.headerContainer {
  border-bottom: 1px solid #e0e0e0;
  padding: 12px;
  .title {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.headerContainer > .headerContent {
  display: grid;
  grid-template-columns: 1fr auto;
  height: auto;
  padding: 0px 16px;
}

.headerContainer > .headerContent > button {
  margin: auto;
}

.bodyContainer {
  overflow-y: scroll;
  padding-left: 16px;
  padding-right: 16px;
}

/* width */
.bodyContainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.bodyContainer::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.bodyContainer::-webkit-scrollbar-thumb {
  background: rgb(181 178 178);
  border-radius: 10px;
}

.footerContainer {
  border-top: 1px solid #e0e0e0;
}

.footerContainer > .footerContent {
  display: grid;
  grid-template-columns: auto 1fr;
  height: auto;
  padding: 12px;
}

.footerContainer > .footerContent > .footerBtn {
  margin: auto;
  grid-template-columns: auto auto;
}

.footerContainer > .footerContent > .footerBtn > button:first-child {
  margin: 0 10px;
}
