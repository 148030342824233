@import 'App.scss';

.agentsBladeBodyContainer {
    margin-top: 1rem;
    grid-template-columns: 100%;
    grid-gap: 1rem;
    .headerContainer {
      grid-template-columns: 1fr auto;
      padding-bottom: 0px!important;
      .headerTitleContainer {
        display: flex !important;
        align-items: center;
      }
    }
  }