@import "App.scss";

.tagListContainer {
  .tabContainer {
    width: 40rem;
    margin-right: auto;
    margin-left: initial;
  }
}

.bladeBodyContainer {
    max-height: 15rem;
    grid-gap: 0.7rem;
}
