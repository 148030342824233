@import 'App.scss';

.backdrop {
    background-color: white !important;
    z-index: 1000 !important;
}

.showScrollbar {
    /* ScrollbarHide.css */
    /* Hide the scrollbar */
    ::-webkit-scrollbar {
      width: 0.5em!important; /* Set the width of the hidden scrollbar */
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1!important; /* Set the background color of the track */
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background-color: #888!important; /* Set the color of the handle */
    }
}