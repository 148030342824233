@import 'App.scss';

.formContainer {
  grid-template-columns: 1fr 1fr;
  .themeContainer {
    grid-template-columns: 1fr auto;
    grid-gap: 1rem;
    align-items: center;
  }
  .thumbImage {
    width: 400;
  }

  .changeBtn {
    width: 10rem;
    margin: auto;
    margin-top: 1rem;
    background-color: $white;
  }

  .dropzoneContainer {
    background: #ffffff;
    border: 1px solid #bababa;
    border-style: dashed;
    border-radius: 6px;
    padding: 1rem;
    margin: 8px 0;
    cursor: pointer;
  }

  .mtb1 {
    margin: 1rem 0;
  }

  .checkTitle {
    margin: 8px 0;
    text-align: center;
  }

  .previewImage {
    display: flex;
    justify-content: center;
    img {
      object-fit: contain;
    }
  }

  .fileUploadText {
    grid-template-columns: auto;
    grid-gap: 1rem;
    justify-content: center;
    div {
      text-align: center;
      width: 100%;
    }
  }

  .dropzoneText {
    margin: auto;
    margin-left: 1px;
  }
}

.loader {
  min-height: 80vh;
  justify-content: center;
  align-items: center;
}

.dropZoneLoader {
  min-height: 10rem;
  justify-content: center;
  align-items: center;
}
