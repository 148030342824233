@import 'App.scss';

.directCobContainer {
  justify-content: center;
  align-items: center;
  .loader {
    height: 10rem;
    justify-content: center;
    align-items: center;
  }
}
