@import 'react-toastify/dist/ReactToastify.css';

:root {
  --primary: #44a3db;
  --secondary: #edf6fc;
  --summaryScoreBackground: #e9f2f8;
  --summaryScoreBorder: #8CC0E8;
  --safetyScoreBackground: #f7e8e7;
  --safetyScoreBorder: #F9706B;
  --speedingScoreBackground: #eafaf0;
  --speedingScoreBorder: #89EBB0;
  --geoScoreBackground: #faf6e3;
  --geoScoreBorder: #FAE178;
} // we can also define variables with JavaScript

/*colors variables*/

$primary: var(--primary);
$secondary: var(--secondary);
$error: #f9635e;
$red20: #fad6d6;
$success: #5abfae;
$warning: #f5b529;
$waiting: #f2b90c;
$white: #ffffff;
$subTitle1: #636363;
$paper: #f8f8f8;
$body: #f8f8f8;
$grey: rgb(102, 102, 102);
$scrollBar: rgb(181 178 178);

/*box-shadow variables*/

$bladeboxShadow: -8px -2px 20px -10px rgba(0, 0, 0, 0.25);

/*background linear gradient*/
$bg-linear-gradient: linear-gradient(180deg, #44a3db 0%, #0d6192 100%);

@font-face {
  font-family: 'Inter-Medium'; /*Can be any text*/
  src: local('Inter-Medium'),
    url('./theme/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter-Regular'; /*Can be any text*/
  src: local('Inter-Regular'),
    url('./theme/fonts/Inter-Regular.ttf') format('truetype');
}

main {
  min-height: 100vh !important;
}

body {
  margin: 0 !important;
}

.fullLogo {
  text-align: center;
  img {
    object-fit: contain;
  }
}

.flexAlignCenter {
  display: flex;
  align-items: center;
  > div {
    width: 100%;
  }
}

.myCarrierInactiveIcon > svg {
  opacity: 0.2;
}

.termsAndConditionButton {
  min-width: 0px !important;
  color: black !important;
}

.inactiveButton {
  opacity: 0.2;
  min-width: 0px !important;
  color: black !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: inherit !important;

  &.elevated {
    margin-top: -5px!important;
  }
}

.containerLoader {
  height: 100vh;
  justify-content: center;
  align-items: center;
}

/*MUI style overrides*/

.MuiDataGrid-panel {
  left: 62% !important;
  top: -4rem !important;
  width: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

/* Table CSS*/
.columnDescriptionContainer {
  grid-template-rows: 1rem 1rem;
  grid-gap: 8px;
  .descriptionText {
    font-size: 12px; 

    &.elevated {
      margin-top: -20px;
    }
  }
  &.withoutTitle {
    grid-gap: 2px;
    grid-template-rows: 0.5rem 0.5rem;
  }
}

.indexContainer {
  display: flex !important;
  justify-content: flex-start!important;
  div {
    font-size: 12px;
  }
}

/* Cells for new Risk Dashboard table */
.summaryScoreCell {
  border-left: 1px solid var(--summaryScoreBorder);
  background-color: var(--summaryScoreBackground);
}
.safetyScoreStart {
  border-left: 1px solid var(--safetyScoreBorder);
  background-color: var(--safetyScoreBackground);
}
.safetyScoreCell {
  background-color: var(--safetyScoreBackground);
}
.speedingScoreStart {
  border-left: 1px solid var(--speedingScoreBorder);
  background-color: var(--speedingScoreBackground);
}
.speedingScoreCell {
  background-color: var(--speedingScoreBackground);
}
.geoScoreStart {
  border-left: 1px solid var(--geoScoreBorder);
  background-color: var(--geoScoreBackground);
}
.geoScoreCell {
  background-color: var(--geoScoreBackground);
}

.withBorder {
  border-left: 1px solid gray;
}

/* Secondary Carriers percentiles table */

.withBottomBorder {
  border-bottom: 1px solid gray;
}

.percentileTable {
  display: flex !important;
  &.poor {
    color: #EA1C04;
  }
  &.average {
    color: #4276C9;
  }
  &.great {
    color: #34864E;
  }
  &.speedingScoreStart {
    border-left: 1px solid gray;
  }
  &.columnCellBorder {
    border-left: 1px solid rgba(224, 224, 224, 1);
  }
  &.center {
    justify-content: center !important;
  }
  &.firstRow {
    background-color: #FEFCD7;
    font-weight: 800;
  }
}

.centeredCell {
  margin-left: auto!important;
  margin-right: auto!important;
  padding-top: 1.2rem!important;
  
  & > div {
    text-align: center!important;
  }
}

.columnGroupTitle {
  width: 100% !important;
  max-width: 100% !important;

  & .MuiDataGrid-columnHeaderDraggableContainer {
    height: auto;

    & .MuiDataGrid-columnHeaderTitleContainer {
      border-bottom-width: 0px;
    }

    & > div {
      border: 0px;
      border-color: transparent;
      padding-bottom: 1px;
    }
  }

  &.centered .MuiDataGrid-columnHeaderDraggableContainer {
    margin-left: auto;
    margin-right: auto;
  }
}