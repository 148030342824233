@import 'App.scss';

.bladeBodyContainer {
    height: 5rem;
    .searchContainer {
        grid-template-columns: 12rem auto auto auto auto auto;
        grid-gap: 1rem;
        .searchBtn {
            margin: auto;
        }
    }
}