@import "App.scss";

.selectionBg {
  background-color: $secondary;
}

.tableHeaderBarContainer {
  grid-template-columns: 1fr 25rem;
  align-items: center;
  padding: 12px;
  .filterBarContainer {
    margin-left: auto;
    .filterBarButton {
      border-radius: 50% !important;
      min-width: 0px !important;
      width: 35px;
      height: 35px;
      span {
        margin: inherit !important;
      }
    }
    .rowSelectionContainer {
      grid-template-columns: 1fr 3rem 3rem 3rem;
      align-items: center;
      grid-gap: 1rem;
    }
  }
}

.filterContainer {
  border-top: 1px solid #8080803b;
  display: grid;
  align-items: center;
  // Three-column layout (with menu button)
  &.threeColumns {
    grid-template-columns: 3rem 3rem auto;
  }
  // Two-column layout (without menu button)
  &.twoColumns {
    grid-template-columns: 3rem auto;
  }
  .moreContainer {
    justify-content: center;
    border-right: 1px solid #8080803b;
  }
  .filterIconContainer {
    justify-content: center;
  }
  .noFieldset {
    margin: 0;
    div {
      fieldset {
        display: none;
      }
    }
  }
}
