@import 'App.scss';

.textFieldContainer {
  grid-template-columns: auto auto auto;
  grid-column-gap: 2rem;
}

.loader {
  min-height: 80vh;
  justify-content: center;
  align-items: center;
}

.actionContainer {
  grid-template-columns: auto auto;
  justify-content: end;
  grid-gap: 1rem;
  > button {
    width: 12rem;
  }
}
