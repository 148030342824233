@import 'App.scss';

.cardMainContainer {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  .cardContentContainer {
    justify-content: center;
    .logoContainer {
      text-align: center;
      img {
        object-fit: contain;
      }
    }
  }
}

.cardDisabled {
  background-color: lightgray !important;
}

.cardReconnect {
  background-color: #b4eecba3 !important;
}

.loaderContainer {
  height: 70vh;
  justify-content: center;
  align-items: center;
}

.mobileResponsiveContainer {
  .eldColumns {
    grid-template-columns: 1fr 1fr;
    padding: 1rem 0;
    border-bottom: 1px solid #f5efef;
  }
  .eldItems {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1rem 8px;
    margin: 1rem 0;
    border-bottom: 1px solid #f5efef;
  }
}

.mobileHeaderContainer {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto !important;
  justify-content: center;
}
