.filterButtonGroup {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 5px 0;
}

.filterButtonGroup::-webkit-scrollbar {
  height: 5px;
}

.filterButtonGroup::-webkit-scrollbar-thumb {
  background-color: rgba(202, 201, 201, 0);
  border-radius: 10px;
}

.filterButtonGroup:hover::-webkit-scrollbar-thumb {
  background-color: rgba(202, 201, 201, 0.575);
}

.filterButtonGroup::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 10px;
}
