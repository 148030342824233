@import 'App.scss';

.loginWrapperContainer {
  height: 100%;
  grid-template-columns: 1fr 1fr;
  .demoTxtContainer {
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 7px;
  }
}

.bgGrey {
  background-color: $body;
  grid-template-rows: 1fr auto;
}

.marketingContainer {
  grid-template-rows: 5rem 1fr 5rem;
  grid-gap: 7rem;
  background: $primary;
  box-shadow: 8px 0px 32px -8px rgba(0, 0, 0, 0.16);
  border-radius: 0px 8px 8px 0px;
  padding: 0 2rem;
  > div {
    max-width: 600px;
  }
  .transmittingContainer {
    grid-template-columns: auto auto auto auto;
    grid-gap: 4rem;
    justify-content: center;
    align-items: center;
    .transmittingContent {
      grid-gap: 7px;
    }
  }
  .blogContainer {
    justify-content: center;
    grid-gap: 1rem;
    .blogButton {
      background-color: $white;
      width: 13rem;
    }
  }
}

.loginContainer {
  height: 32rem;
  width: 30rem !important;
  margin: auto;
  grid-gap: 1rem;
  .brandDetail {
    grid-gap: 1rem;
  }
  .formContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .contentLinkContainer {
    grid-template-columns: auto auto;
    align-items: center;
    padding-bottom: 1rem;
  }
}

.buttonContainer {
  flex-grow: 1;
  height: 50px;
}
