@import 'App.scss';

.riskDetailsContainer {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
}

.column-color {
    .pale-blue {
        background-color: #c7dff1;
    }
    .pale-red {
        background-color: #fdaaa7;
    }
    .pale-green {
        background-color: #b4eecb;
    }
    .pale-yellow {
        background-color: #faeaa9;
    }
}

.printContainer {
    grid-template-columns: auto;
}