@import 'App.scss';

.summaryContainer {
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    .summaryMainContainer {
        grid-template-rows: 8rem 1fr;
    }

    .summaryHeaderContainer {
        background-image: linear-gradient(#3D5868,#162026);
        border-radius: 11px;
        margin-bottom: 1rem;

        .summaryList {
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
        }

        .itemContainer {
            grid-template-columns: 2rem auto;
            align-items: center;
            grid-gap: 8px;

            .summaryDataContainer {
                grid-template-columns: 2rem 2rem 2rem 2rem 2rem 2rem;

                & > div {
                    font-size: 1.4rem;
                }
            }

            .summaryContentContainer {
                display: block;
            }
        }
    }
}

.printContainer {
    grid-template-columns: auto;
}