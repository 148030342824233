@import 'App.scss';

.paymentCategoryContainer {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  min-height: 10rem;
  max-height: 15rem;
  .icon {
    height: 3rem;
    width: 3rem;
    margin: auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .chipContainer {
    grid-template-columns: 3rem 3rem 3rem;
    grid-column-gap: 1rem;
    justify-content: center;
  }
  .btnContainer {
    justify-content: center;
    grid-gap: 1rem;
  }
}

.muiIcon {
  color: #44a3db;
}