@import 'App.scss';

.fleetPercentileContainer {
    margin-top: 1rem;
    grid-template-columns: 100%;
    grid-gap: 1rem;
    .summaryMainContainer {
      grid-template-rows: 7rem 1fr;
    }
    .headerContainer {
      grid-template-columns: 1fr auto;
      padding-bottom: 0px!important;
      .headerTitleContainer {
        display: flex !important;
        align-items: center;
      }
    }
    .summaryHeaderContainer {
      background-color: black;
      border-radius: 11px;
      margin-bottom: 1rem;
      .summaryList {
        display: flex;  
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 10px 20px;
      }
      .itemContainer {
        grid-template-columns: 2rem auto;
        align-items: center;
        grid-gap: 8px;
        .summaryDataContainer {
          grid-template-columns: 2rem 2rem 2rem;
        }
        .summaryContentContainer {
          display: block;
        }
      }
    }
}