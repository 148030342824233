@import 'App.scss';

.paymentsContainer {
  .tabContainer {
    width: 40rem;
    margin-right: auto;
    margin-left: initial;
  }
  .bladeBodyContainer {
    .bladeTabContainer {
      display: block;
    }
  }
}
