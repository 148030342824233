@import 'App.scss';

.tooltipDisabled {
    width: 100%;
}

.disabled {
    background-color: rgba(255, 0, 0, 0.1);
    border-color: red;
}

.disabled .MuiOutlinedInput-root fieldset {
    border-color: red;
}