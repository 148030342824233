@import 'App.scss';

.loaderContainer {
  height: 70vh;
  justify-content: center;
  align-items: center;
}

.modalContentContainer {
  padding: 1rem;
  .cancelBtn {
    margin-left: auto;
  }
  .titleContainer {
    justify-content: center;
    grid-gap: 1rem;
    text-align: center;
  }
  .logoSwitchContainer {
    grid-template-columns: 1fr auto 1fr;
    grid-gap: 2rem;
    justify-content: center;
    align-items: center;
    .logoContainer {
      text-align: center;
      img {
        object-fit: contain;
      }
    }
  }
  .sectionTitle {
    font-size: 12px;
    text-transform: uppercase;
  }
  .sectionListContainer {
    grid-template-columns: 1fr 25rem;
    margin-top: 3rem;
    letter-spacing: 2px;
    grid-gap: 4rem;
    > div {
      grid-template-rows: 1rem auto;
      padding: 1rem;
    }
    .actionSection {
      background-color: $paper;
    }
    .requireContainer {
      margin: 1rem 0;
      .requireItem {
        grid-template-columns: auto 1fr;
        grid-gap: 1rem;
        margin-bottom: 1rem;
        .checkboxContainer {
          grid-template-columns: 2rem auto;
        }
      }
      .disableContainer {
        opacity: 0.3;
      }
    }
  }
  .permissionBtnContainer {
    grid-template-columns: auto auto;
    justify-content: flex-end;
    grid-gap: 1rem;
    margin: 1rem 0;
  }
  .authorizeBtnContainer {
    grid-template-columns: auto auto;
    justify-content: space-between;
    grid-gap: 1rem;
    margin: 1rem 0;
  }
  .infoContainer {
    background: #e8f4fa;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 70%;
    border-radius: 10px;
    margin: 1rem auto;
    > span {
      margin-right: 1rem;
    }
  }
  .formContainer {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
}

.backdrop {
  background-color: white !important;
  z-index: 1000 !important;
}

.verifyLoaderContainer {
  justify-content: center;
  margin: 1rem 0;
}

.showScrollbar {
  /* ScrollbarHide.css */
  /* Hide the scrollbar */
  ::-webkit-scrollbar {
    width: 0.5em!important; /* Set the width of the hidden scrollbar */
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1!important; /* Set the background color of the track */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #888!important; /* Set the color of the handle */
  }
}

/*********** Mobile Responsive **********/

.mobileLogoSwitch {
  grid-template-columns: auto !important;
  grid-template-rows: 3rem auto 4rem;
  grid-gap: 0 !important;
}

.mobileSectionList {
  grid-template-columns: auto !important;
  margin-top: 1rem !important;
  .actionSection {
    justify-content: center;
  }
  .actionSectionCentered {
    justify-content: center;
  }
  .requireItem {
    margin-bottom: 10px;
  }
}

.mobilePermissionBtn {
  grid-template-columns: auto !important;
  justify-content: center !important;
}

.mobileModalContent {
  .titleContainer {
    > div {
      text-align: center;
    }
  }
}

.mobileFormContainer {
  grid-template-columns: auto !important;
}

.mobileInfoContainer {
  width: 100% !important;
}

.mobileArrow {
  transform: rotate(90deg);
}

.authorizeTitle {
  font-size: 12px;
  line-height: 16px;
}

.subtitleContainer {
  span {
    font-size: 10px;
    line-height: 8px;
  }
  div {
    font-size: 10px;
    line-height: 8px;
    text-align: center;
  }
}

.loadingModal{
  display: flex;
  align-items: center;
  width: 450px;
  height: 450px;
  justify-content: center;
}