@import "App.scss";

.formContainer {
  height: 100%;
  grid-template-columns: 1fr 1fr;
}

.tabPanelContainer {
  min-height: 24rem;

  .underConstructionTypo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .switchContainer {
    grid-template-columns: 1fr auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .btnContainer {
    padding-top: 1rem;
    justify-content: end;
  }
}
