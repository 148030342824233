@import "App.scss";

.emailAccountsContainer {
  .tabContainer {
    width: 40rem;
    margin-right: auto;
    margin-left: initial;
  }
}

.bladeBodyContainer {
  max-height: 10rem;
}
